import React, { Component } from 'react'
import { Growl } from 'primereact/growl';
import { connect } from 'react-redux';


class MessageDisplay extends Component {


    componentDidMount() {
        this.messages.show(this.props.message);
    }

    componentDidUpdate() {
        this.messages.show(this.props.message);
    }
    render() {
        return (<Growl ref={(el) => this.messages = el} />)
    }
}


function mapStateToProps(state) {
    return {
        message: state.message,
    }
}


export default connect(mapStateToProps)(MessageDisplay);

