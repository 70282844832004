import { SET_SELECTED_TABLE } from '../actions/types';


export default function (state = null, action) {

    switch (action.type) {
        case SET_SELECTED_TABLE:
            return action.payload;
        default: return state;
    }
}