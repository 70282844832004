import { combineReducers } from 'redux';
import userReducer from './userReducer.js';
import usersReducer from './usersReducer.js';
import loadingReducer from './loadingReducer.js';
import navReducer from './navReducer.js';
import messageReducer from './messageReducer.js';
import selectedItemReducer from './selectedItemReducer';
import tablesReducer from './tablesReducer';
import selectedTableReducer from './selectedTableReducer';
import productReducer from './productReducer';
import categoryReducer from './categoryReducer';
import billReducer from './billReducer';
import getLastOrdersReducer from './getLastOrdersReducer';

export default combineReducers(
    {
        user: userReducer,
        users: usersReducer,
        loading: loadingReducer,
        nav: navReducer,
        message: messageReducer,
        selectedItem: selectedItemReducer,
        selectedTable: selectedTableReducer,
        tables: tablesReducer,
        lastOrders: getLastOrdersReducer,
        categories: categoryReducer,
        products: productReducer,
        bill: billReducer,
    }
)