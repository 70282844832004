import { LOAD_BILL, LOADING_SHOW, MESSAGE_SHOW, CHANGE_NAVIGATION} from './types';
import axios from 'axios';
import { expiredLogout, actionError } from './unAuthorised';
// import { getTableBillId, generateTableBillId } from 'xxx';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function updateBill(state) {
    return [{
        type: LOAD_BILL,
        payload: state,
    }
    ]

}


export function loadBill(token, tableId) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/bills/` + tableId, {
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    // console.log("responseXXX",response)
                    dispatch(updateBill(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("loadBill exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "load bill"))
            })
    }
}

export function addProduct(token, tableBillId, productID, productPrice, tableId, rollingBillId) {
    return function (dispatch) {
        // console.log("TOKEN = " + token + " |  PRODUCT ID = " + productID + " |  PRODUCT PRICE = " + productPrice + " |  TABLE ID = " + tableId);
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId + `/add/`, {
            "tableBillId": tableBillId,
            "productId": productID,
            "price": productPrice, 
            "quantity": 1,
            "rollingBillId": rollingBillId,
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateBill(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("addProducts exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "add product"))
            })
    }

}

export function updateMultipleQuantities(token, tableId, productQuantities){
    return function(dispatch){
        axios.defaults.headers.common['token'] = token;
        axios.defaults.headers.post['Content-Type'] = 'text/plain';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId +  `/updatemultiple/`, productQuantities)
        .then(res=>{
            const response = res.data;
            if (res.status === 200){
                // console.log("updateMultipleQuantities 200 success");
                // console.log(res.data);
                dispatch(updateBill(response))
            }
        }).catch((e)=>{
            if (e.response != null){
                console.log("Update multiple quantities exception " + e.response.status);
                if (e.response.status === 401){
                    console.log("Response error 401");
                    dispatch(expiredLogout());
                }
            }
            dispatch(actionError(e, "update multiple products"))
        })
    }
}


export function createMultipleBillItems(token, tableId, productQuantities){
    return function(dispatch){
        axios.defaults.headers.common['token'] = token;
        axios.defaults.headers.post['Content-Type'] = 'text/plain';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId +  `/addmultiple/`, productQuantities)
        .then(res=>{
            const response = res.data;
            if (res.status === 200){
                // console.log("updateMultipleQuantities 200 success");
                // console.log(res.data);
                dispatch(updateBill(response))
            }
        }).catch((e)=>{
            if (e.response != null){
                console.log("Update multiple quantities exception " + e.response.status);
                if (e.response.status === 401){
                    console.log("Response error 401");
                    dispatch(expiredLogout());
                }
            }
            dispatch(actionError(e, "update multiple products"))
        })
    }
}

// GB Changes - new API
export function updateQuantity(token, productID, quantity, tableId, billId, billItemId) {

    return function (dispatch) {
        // console.log("PRODUCT ID = " + productID + " |  PRODUCT QUANTITY = " + quantity + " |  TABLE ID = " + tableId + "|  BILL ID = " + billId);
        axios.defaults.headers.common['token'] = token;
        axios.defaults.headers.post['Content-Type'] = 'text/plain';
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId + `/update/`, {
            "billId":billId,
            "productId": productID,
            "quantity": quantity,
            "billItemId": billItemId
        })
            .then(res => {
                const response = res.data;
                // console.log("res.status === 200 => res.data = " + JSON.stringify(res.data));
                if (res.status === 200) {
                    // console.log("aaaaaaaaaaaaaaaa");
                    // console.log(response);
                    dispatch(updateBill(response))              // <+++++++++++++++++++
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("updateQuantity exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "update product"))
            })
    }

}


export function deleteProduct(token, productBillId, tableId) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        axios.defaults.headers.post['Content-Type'] = 'text/plain';
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId + `/delete/`,
            productBillId
        )
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateBill(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("deleteProduct exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "delete product"))
            })
    }
}

export function updateProduct(token, note, billItemId, tableID) {

    return function (dispatch) {
        // console.log("TOKEN = " + token + " |  BillItem ID = " + billItemId + " |  PRODUCT note = " + note + " |  TABLE ID = " + tableID);
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableID + `/update/`, {
            "note": note,
            "billItemId": billItemId
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateBill(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("addProducts exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "add product"))
            })
    }

}

// export function submitBill(token, bill, tableId) {
    export function submitBill(token, bill, tableId, items="active") {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        let url = `${API_ENDPOINT}/iorderws/api/bills/` + tableId 
        if (items=="active"){
          url = url + `/print/`;
        } if (items=="all"){
            url = url + `/printall/`;
          }
        // return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId + `/print/`, {
            return axios.post(url, {
            bill
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateBill(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("Submitbill exception " + e.response.status);
                    if (e.response.status === 401) {    
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "Submit Bill"))
            })
    }

}

export function finaliseBill(token, bill, tableId) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/bills/` + tableId + `/finalise/`, {
            bill
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    dispatch(updateBill(response))
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("FinaliseBill exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                dispatch(actionError(e, "Finalise Bill"))
            })
    }

}





