import { LOGIN_USER_SUCCESS, LOGOUT_USER, LOGIN_USER_ERROR } from '../actions/types';


export default function (state = { id: null }, action) {

    switch (action.type) {
        case LOGIN_USER_SUCCESS:
            return action.payload;
        case LOGOUT_USER:
            return action.payload;
        case LOGIN_USER_ERROR:
            return action.payload;
        default: return state;
    }
}

