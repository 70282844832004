import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { Dropdown } from 'primereact/dropdown';
import { editTable } from '../../actions/tableActions';

class TableEdit extends Component {

    state = {
        tableID: 0,
        statusID: {label: '', val: 0},
        seats: 0,
        tableName: "",
        occupiedTime: 0
    };

    tableStatuses = [
        {label: 'Unused', val: 0},
        {label: 'Reserved', val: 1},
        {label: 'Available', val: 2},
        {label: 'Occupied', val: 3},
        {label: 'Cleaning', val: 4}
    ];
    filteredTableStatuses=[];

    componentDidMount() {
        this.setState({
            tableID: this.props.selectedTable.tableID,
            statusID: this.tableStatuses[this.props.selectedTable.statusID],
            seats: this.props.selectedTable.seats,
            tableName: this.props.selectedTable.tempTable,
            occupiedTime: this.props.selectedTable.occupiedTime
        });
        
        this.props.loading(false);
        this.originalStatusDescription = this.tableStatuses[this.props.selectedTable.statusID].label;
        this.filteredTableStatuses = this.tableStatuses.filter(v=>v.val !== this.props.selectedTable.statusID);
    }

    tables = () => {
        this.props.loading(true);
        this.props.changeNav('tablesList')
    }

    editTable= (e) => {
        e.preventDefault();
        // console.log("Edit Table TableID = " + this.state.tableID);
        // console.log("Edit Table Status ID = " + this.state.statusID.value);
        // console.log("Edit Table Seats = " + this.state.seats);
        // console.log("Edit Table user's token = " + this.props.user.token);
        this.props.editTable(this.props.user.token, this.state.tableID, this.state.statusID.val, this.state.seats, this.state.tableName);
        this.props.loading(true);
    }

    render() {
        return (
            <div style={{'margin':'auto','display':'block','textAlign':'center'}}>
                <h1 style={{ margin: 10, color: '#3f51b5' }}>Edit Table {this.state.tableID}</h1>
                <form onSubmit={this.editTable}>

                <span>Table name: </span><br/>
                    <InputText style={{ margin: 5, width: '200px', 'textAlign':'center', title:'Change Table name here' }} value={this.state.tableName} 
                    onChange={(e) => this.setState({ tableName: e.target.value })} /><br /><br/>

                <span>Number of Seats: </span><br/>
                    <InputText style={{ margin: 5, width: '50px', 'textAlign':'center', title:'Amend seat number here' }} value={this.state.seats} 
                    onChange={(e) => this.setState({ seats: e.target.value })} /><br /><br/>
                    <p>Current status is: <b>{this.tableStatuses[this.props.selectedTable.statusID].label}</b></p>
                    <p>Select a new status (if required) below:</p>
                    <Dropdown optionLabel="label" value={this.state.statusID} options={this.filteredTableStatuses} 
                    onChange={(e) => { this.setState({ statusID: e.value }) }}  /><br /><br/>
                    <Button style={{ margin: 5, cursor: 'pointer', transition: 'green 0.3s ease' }} label="Save" icon="pi pi-check" className="p-button-rounded p-button-success" iconPos="right" />
                
                <Button style={{ margin: 5, cursor: 'pointer', transition: 'red 0.3s ease' }} onClick={() => { this.tables() }} label="Cancel" icon="pi pi-times" className="p-button-rounded p-button-danger" iconPos="right" />
                </form>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        selectedTable: state.selectedTable,
        user: state.user,
        manufacturers: state.manufacturers
    }
}


function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loading: loading,
        changeNav: changeNav,
        editTable: editTable,
    }, dispatch)
}


export default connect(mapStateToProps, matchDispatchToProps)(TableEdit);
