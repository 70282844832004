import React, { Component } from 'react'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { addUser } from '../../actions/userActions'



class UserAdd extends Component {

    state = {
        value: ''
    };


    add = (e) => {
        e.preventDefault();
        this.props.loading(true);
        this.props.addUser(this.state.value, this.props.user.token);
    }

    users = () => {
        this.props.loading(true);
        this.props.changeNav('users')
    }

    componentDidMount() {
        this.props.loading(false);
    }

    render() {

        return (
            <React.Fragment>
                <div>
                    <h1 style={{ margin: 10, color: '#3f51b5' }}>Add User</h1>
                    <form onSubmit={this.add}>
                        <InputText required style={{ margin: 5 }} value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} /><br />
                        <Button type="submit" style={{ margin: 5 }} label="Create" icon="pi pi-check" className="p-button-rounded p-button-success" iconPos="right" />
                    </form>
                    <Button style={{ margin: 5 }} onClick={() => { this.users() }} label="Cancel" icon="pi pi-times" className="p-button-rounded p-button-danger" iconPos="right" />

                </div>


            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {
    return {
        selectedItem: state.selectedItem,
        user: state.user,

    }
}


function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loading: loading,
        changeNav: changeNav,
        addUser: addUser,
    }, dispatch)
}


export default connect(mapStateToProps, matchDispatchToProps)(UserAdd);