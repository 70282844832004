import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadUsers } from "../../actions/userActions";
import { setSelectedItem } from "../../actions/selectedItemActions";
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { Button } from 'primereact/button';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

class UserList extends Component {

    edit = (item) => {
        this.props.loading(true);
        this.props.setSelectedItem(item);
        this.props.changeNav('userEdit')
    }

    add = (item) => {
        this.props.loading(true);
        this.props.changeNav('userAdd')
    }

    userList = () => {
        return (<div className="p-grid">
            {this.props.users.filter((item) => item.email.toUpperCase().includes(this.props.search.toUpperCase())).map((user) => {
                return <div key={user.id} className="p-col">
                    <Button onClick={() => this.edit(user)} className={user.enabled ? '' : 'p-button-secondary'} style={{ width: 200, margin: 5, marginBottom: 0 }} label={user.email + '\n' + user.displayname} />
                </div>
            })}
        </div>
        )


    }

    componentDidMount() {
        this.props.loadUsers(this.props.user.token);
        this.props.loading(false);
    }

    render() {
        return (
            <div>
                <h1 style={{ margin: 10, color: '#3f51b5' }}>Users</h1>

                {this.userList()}
                <Fab onClick={() => { this.add() }}
                    style={{
                        position: 'absolute',
                        bottom: 5,
                        right: 5
                    }} color='primary'>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        users: state.users,
        user: state.user,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loadUsers: loadUsers,
        loading: loading,
        setSelectedItem: setSelectedItem,
        changeNav: changeNav
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(UserList);
