import { LOAD_TABLES, DELETE_TABLE } from '../actions/types';


export default function (state = [], action) {

    switch (action.type) {
        case LOAD_TABLES:
            return action.payload;
        case DELETE_TABLE:
            const tableIdDeleted = action.tableId;
            return state.filter(table => table.tableID !== tableIdDeleted);
        default: return state;
    }
}
