import { LOADING_SHOW } from '../actions/types';


export default function (state = false, action) {

    switch (action.type) {
        case LOADING_SHOW:
            return action.payload;
        default: return state;
    }
}