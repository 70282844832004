import { CHANGE_NAVIGATION, LOADING_SHOW } from './types';


export function changeNav(state) {
    return [{
        type: CHANGE_NAVIGATION,
        payload: state
    },
    {
        type: LOADING_SHOW,
        payload: true
    }
    ]
}
export function cancelNav(state) {
    return [{
        type: CHANGE_NAVIGATION,
        payload: ''
    },
    {
        type: LOADING_SHOW,
        payload: false
    }
    ]
}

