import { SET_SELECTED_ITEM } from '../actions/types';


export default function (state = null, action) {

    switch (action.type) {
        case SET_SELECTED_ITEM:
            return action.payload;
        default: return state;
    }
}