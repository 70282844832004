import { LOAD_TABLES, LOADING_SHOW, MESSAGE_SHOW, CHANGE_NAVIGATION, LOGIN_USER_ERROR, 
    DELETE_TABLE, GET_TABLE_BILL_ID } from './types';
import axios from 'axios';
import {expiredLogout, actionError} from './unAuthorised';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function updateTables(state) {
    return [{
        type: LOAD_TABLES,
        payload: state
    }
    ]
}



function editTable_success() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'Table edited', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: 'tablesList'
    }
    ]

}

function createTabTable_success(){
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'Tab Table created', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: 'tablesList'
    }]
}

function deletedTabTable_success(){
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'Tab/Table deleted', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: 'tablesList'
    }]
}

export function loadTables(token) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/tables/`, {
        })
        .then(async (res) => { 
            const response = res.data;
            // console.log(res.status);
            if (res.status === 200) {
              const lbitt = await linkBillItemsToTables(response, token);
              dispatch(updateTables(lbitt));
            }
          }).catch((e) => {
                if(e.response != null){
                console.log("loadTables exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout())
                    }
                }
                            
            dispatch(actionError(e, "load tables"))
                            })
    }
}

async function linkBillItemsToTables(tables, token){
    const updatedTables = await Promise.all(tables.map(async (table) => {
        let billData = await loadBillsItemsForTable(token, table.tableID);
        return { ...table, bill: billData };
    }));
    return updatedTables
}

//GB
function loadBillsItemsForTable(token, tableId) {

        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/bills/` + tableId, {
        })
            .then(res => {
                const response = res.data;
                if (res.status === 200) {
                    return response;
                }
            }).catch((e) => {
                if (e.response != null) {
                    console.log("loadBillsItemsForTable exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        // dispatch(expiredLogout())
                    }
                }
                // dispatch(actionError(e, "load bill"))
            })
}

export function removeTable(tableId) {
    return {
      type: DELETE_TABLE,
      tableId,
    };
  }

export function deleteTabTable(token, tableId ){
    return function(dispatch){
        axios.defaults.headers.common['token'] = token;
        return axios.post (`${API_ENDPOINT}/iorderws/api/tables/${tableId}`)
            .then(res=>{
                if (res.status === 200){
                    dispatch(removeTable(tableId));
                    dispatch(deletedTabTable_success())
                } else {
                    dispatch(actionError(res.status, "delete tabtable"))
                }
            }).catch((e)=>{
                if (e.response != null){
                    console.log("deleteTabTable exception " + e.response.status);
                    if (e.response.status === 401) {
                        console.log("Response error 401");
                        dispatch(expiredLogout());
                    }
                }
            });
    };
}

// gb
export function createTabTable(status, seats, tabName, createTable, token){
    return function (dispatch){
        axios.defaults.headers.common['token'] = token;
        return axios.post (`${API_ENDPOINT}/iorderws/api/tables/create?createTable=${createTable}`, {
            "statusID": status,
            "seats": seats,
            "tempTable": tabName,
        }).then(res=>{
            if (res.status ===200){
                dispatch(createTabTable_success())
            } else { 
                dispatch (actionError(res.status, "create tabtable"))
            }
        }).catch((e)=>{
            if (e.response != null){
                console.log("createTabTable exception " + e.response.status);   
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
            }
        })
    }
}

export function editTable(token, tableId, status, seats, tableName, adHocFlag=0) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/tables/`, {
            "tableID": tableId,
            "statusID": status,
            "seats": seats,
            "tempTable": tableName,
        }).then(res => {
            if (res.status === 200) {
                if (adHocFlag==0){          // Don't want to redirect for certain table edits
                    dispatch(editTable_success())
                }
            } else {
                dispatch(actionError(res.status, "edit tables"))
            }}
        ).catch((e) => {
                if(e.response != null){
                console.log("editTables exception " + e.response.status);
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
                }
                      
        })
    }

}

export function getTableBillId(token) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/billid/current`).then(res => {
            if (res.status === 200) {
                return res;
            } else {
                dispatch(actionError(res.status, "Get TableBillId"))
            }}
        ).catch((e) => {
                if(e.response != null){
                console.log("getTableBillId exception " + e.response.status);
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
                }
                      
        })
    }

}

export function generateTableBillId(token) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.get(`${API_ENDPOINT}/iorderws/api/billid/new`).then(res => {
            if (res.status === 200) {
                return res;
            } else {
                dispatch(actionError(res.status, "Generate TableBillId"))
            }}
        ).catch((e) => {
                if(e.response != null){
                console.log("generateTableBillId exception " + e.response.status);
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
                }
                      
        })
    }

}

// Changes table ID of temp table to table ID of existing table.
// Also -If a tab is converted to a table which already has existing items, then there will be 2 different tableBillId's. There should only be one so need to amend TableBillId to TableBillId of the actual Table.
export function changeTableId(token, tableId, newTableId ) {

    return function (dispatch) {
        axios.defaults.headers.common['token'] = token;
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/tables/amendTableId`, {
            "tableID": tableId,
            "newTableId": newTableId,
        }).then(res => {
            if (res.status === 200) {
                dispatch(editTable_success())
            } else {
                dispatch(actionError(res.status, "edit tables (changeTableId)"))
            }}
        ).catch((e) => {
            if(e.response != null){
                console.log("editTables (changeTableId) exception " + e.response.status);
                if (e.response.status === 401) {
                    console.log("Response error 401");
                    dispatch(expiredLogout())
                }
            }
                      
        })
    }

}


