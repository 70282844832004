import { LOADING_SHOW, MESSAGE_SHOW, CHANGE_NAVIGATION, LOGIN_USER_ERROR } from './types';

export function expiredLogout() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'error', summary: 'Error', detail: 'Login has expired, please log back in.', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: null
    }, {
        type: LOGIN_USER_ERROR,
        payload: {
            token: null
        }
    }, {
        type: LOADING_SHOW,
        payload: false
    }
    ]

}


export function actionError(error, actionName) {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'error', summary: 'Error', detail: 'Unable to ' + actionName + ' : ' + error, closable: false }
    }, {
        type: LOADING_SHOW,
        payload: false
    }]

}