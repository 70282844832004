import { CHANGE_NAVIGATION } from '../actions/types';


export default function (state = '/', action) {

    switch (action.type) {
        case CHANGE_NAVIGATION:
            return action.payload;
        default: return state;
    }
}