import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { InputSwitch } from 'primereact/inputswitch';
import { editUser } from '../../actions/userActions'
import { Dropdown } from 'primereact/dropdown';




class UserEdit extends Component {

    state = {
        email: '',
        enabled: false,
        id: 0,
        displayname: '',
        role: 'USER'
    };

    componentDidMount() {
        this.setState({
            email: this.props.selectedItem.email,
            enabled: this.props.selectedItem.enabled,
            id: this.props.selectedItem.id,
            displayname: this.props.selectedItem.displayname,
            role: this.props.selectedItem.role,
        });
        this.props.loading(false);

    }

    users = () => {
        this.props.loading(true);
        this.props.changeNav('users')
    }

    editUser = (e) => {
        e.preventDefault();
        this.props.loading(true);
        this.props.editUser(this.state.id, this.state.email, this.state.role, this.state.enabled, this.state.displayname, this.props.user.token);
    }


    render() {

        const roles = [
            { label: "USER", value: "USER" },
            { label: "ADMIN", value: "ADMIN" },

        ]
        return (
            <div>
                <h1 style={{ margin: 10, color: '#3f51b5' }}>Edit User</h1>
                <form onSubmit={this.editUser}>
                    <span style={{ margin: 20 }} >
                        <h5>Email</h5>
                        <InputText readOnly value={this.state.email} />
                    </span>
                    <span style={{ margin: 20 }} >
                        <h5>Display name</h5>
                        <InputText required value={this.state.displayname} onChange={(e) => this.setState({ displayname: e.target.value })} />
                    </span>
                    <span style={{ margin: 20 }} >
                        <h5>Role</h5>
                        <Dropdown required value={this.state.role} options={roles} onChange={(e) => { this.setState({ role: e.value }) }} />
                    </span>
                    <span style={{ margin: 20 }} >
                        <h5>Enabled</h5>
                        <InputSwitch checked={this.state.enabled} onChange={(e) => this.setState({ enabled: e.value })} />
                    </span><br />
                    <Button style={{ margin: 5 }} label="Save" icon="pi pi-check" className="p-button-rounded p-button-success" iconPos="right" />
                </form>
                <Button style={{ margin: 5 }} onClick={() => { this.users() }} label="Cancel" icon="pi pi-times" className="p-button-rounded p-button-danger" iconPos="right" />

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        selectedItem: state.selectedItem,
        user: state.user
    }
}


function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loading: loading,
        changeNav: changeNav,
        editUser: editUser
    }, dispatch)
}


export default connect(mapStateToProps, matchDispatchToProps)(UserEdit);
