import { LOGIN_USER_SUCCESS, LOGIN_USER_ERROR, LOGOUT_USER, MESSAGE_SHOW, LOADING_SHOW, CHANGE_NAVIGATION, LOAD_USERS } from './types';
import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export function logoutUser() {
    return [{
        type: LOGOUT_USER,
        payload: { id: null }
    }, {
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Logged out', detail: 'Come back soon!', closable: false }
    }, {
        type: LOADING_SHOW,
        payload: false
    }]

}


function loginUserSuccess(response) {
    return [{
        type: LOGIN_USER_SUCCESS,
        payload: response
    }, {
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'Welcome', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: 'tablesList'
    }
    ]
}

function loginUserError(err) {


    return [{
        type: LOGIN_USER_ERROR,
        payload: {
            id: null
        }
    }, {
        type: MESSAGE_SHOW,
        payload: { severity: 'error', summary: 'Error', detail: 'Unable to login', closable: false }
    },
    {
        type: LOADING_SHOW,
        payload: false
    }
    ]
}

function registerUserSuccess() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'Please log in', closable: false }
    },
    {
        type: LOADING_SHOW,
        payload: false
    }
    ]
}


function registerUserExists() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'info', summary: 'Sorry', detail: 'That email is already used', closable: false }
    },
    {
        type: LOADING_SHOW,
        payload: false
    }
    ]
}


function registerUserError() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'error', summary: 'Sorry', detail: 'Unable to register at this time', closable: false }
    },
    {
        type: LOADING_SHOW,
        payload: false
    }
    ]
}

function startLoading() {
    return {
        type: LOADING_SHOW,
        payload: true
    }
}




export function loginUser(pin) {
    return function (dispatch) {
        dispatch(startLoading());
        // axios.defaults.baseURL = 'http://localhost:8080';
        return axios.post(`${API_ENDPOINT}/iorderws/api/login/`, {
            "pin": pin
        }).then(res => {
            // console.log('axios sent request')

            const response = res.data;

            if (res.status === 200) {
                dispatch(loginUserSuccess(response))
            } else {
                dispatch(loginUserError(response))
            }
        }).catch((e) => {
            dispatch(loginUserError(e));

        })

    }
}

export function doRegister(email, password, displayname) {
    return function (dispatch) {
        dispatch(startLoading());
        return axios.post(`http://tom1:8080/microservices/api/users/register`, {
            "email": email,
            "password": password,
            "displayname": displayname

        })
            .then(res => {
                if (res.status === 201) {
                    dispatch(registerUserSuccess())
                } else {
                    dispatch(registerUserError())
                }
            })
            .catch((e) => {
                if (e.response.status === 409) {
                    dispatch(registerUserExists())
                } else {
                    dispatch(registerUserError())
                }
            })
    }
}



function updateUser(state) {
    return [{
        type: LOAD_USERS,
        payload: state
    }
    ]
}

function addUser_error(error) {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'error', summary: 'Error', detail: 'Unable to create user: ' + error, closable: false }
    }, {
        type: LOADING_SHOW,
        payload: false
    }]

}

function editUser_error(error) {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'error', summary: 'Error', detail: 'Unable to edit user: ' + error, closable: false }
    }, {
        type: LOADING_SHOW,
        payload: false
    }]

}

function addUser_success() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'User created', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: 'users'
    }]

}

function editUser_success() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'success', summary: 'Success', detail: 'User edited', closable: false }
    },
    {
        type: CHANGE_NAVIGATION,
        payload: 'users'
    }]

}

function offline_error() {
    return [{
        type: MESSAGE_SHOW,
        payload: { severity: 'warn', summary: 'Warning', detail: 'Unable to contact server', closable: false }
    }]

}

export function loadUsers(token) {

    return function (dispatch) {
        return axios.post(`/microservices/api/user/list`, {
            "token": token
        }).then(res => {
            const response = res.data;
            if (res.status === 200) {
                dispatch(updateUser(response))
            }
        }).catch((e) => {
            dispatch(offline_error());
        })
    }
}

export function addUser(name, token) {

    return function (dispatch) {
        return axios.post(`http://tom1:8080/microservices/api/user/add`, {
            "name": name,
            "token": token
        }).then(res => {
            if (res.status === 201) {
                dispatch(addUser_success())
            } else {
                dispatch(addUser_error(res.status))

            }
        }).catch((e) => {
            dispatch(addUser_error(e))

        })
    }
}

export function editUser(id, email, role, enabled, displayname, token) {

    return function (dispatch) {
        return axios.post(`http://tom1:8080/microservices/api/user/edit`, {
            "id": id,
            "enabled": enabled,
            "email": email,
            "role": role,
            "displayname": displayname,
            "token": token
        }).then(res => {
            if (res.status === 202) {
                dispatch(editUser_success())
            } else {
                dispatch(editUser_error(res.status))

            }
        }).catch((e) => {
            dispatch(editUser_error(e))

        })
    }
}