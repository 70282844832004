import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLast10Orders } from "../../actions/lastOrdersAction";
import { setSelectedTable } from "../../actions/selectedTableActions";
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { Button } from 'primereact/button';


import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

class OrdersHistory extends Component {

    edit = (item) => {
        this.props.loading(true);
        this.props.setSelectedTable(item);
        this.props.changeNav('editTable')
    }

    select = (item) => {
        this.props.loading(true);
        this.props.setSelectedTable(item);
        this.props.changeNav('productBill')
    }

    formatDate = (timestamp) => {
        var convertedDate = new Date(timestamp);
        return convertedDate.toLocaleString();
    }

    // The orderList function needs: name, price, tableId (this will be replaced by BillNo-currently o/s), timestamp, quantity-currently o/s
    // tableId cannot be used as it will not be unique if a table is used twice.
    
     orderList = () => {
        const itemList = this.props.orders.slice();
        // console.log(itemList);
          function summarizeItemsByTableBillId(itemList) {
            const summary = {};
            
            itemList.forEach((item) => {
            const { tableBillId, tableId, name, price, quantity, timestamp} = item;
            //   summary[tableId] =  tableId;
              if (!summary[tableBillId]) {
                summary[tableBillId] = { tableId, items: [], maximumTimestamp: null, billTotal: 0 };
              }
              let total = price*quantity;
              summary[tableBillId].items.push({ tableId, name, price, quantity, total, timestamp });
              if (timestamp > summary[tableBillId].maximumTimestamp) {
                summary[tableBillId].maximumTimestamp = timestamp;
              }
              summary[tableBillId].billTotal += total;
            });
            return summary
          }
          
        const summarisedList = summarizeItemsByTableBillId(itemList);
        // console.log("summarisedList=>",summarisedList)
        return (<div style={{ 'border': '1px solid darkGrey', "padding": "15px" }} >
                {Object.entries(summarisedList).sort(([a],[b])=>b-a).map(([tableBillId, summary]) => (
                    <div key={tableBillId}>
                    <span style={{ color: 'black', fontSize: '1.2em', fontWeight: 'bold', backgroundColor: 'lightGrey', padding:'5px'}}>Table Number: {summarisedList[tableBillId]["tableId"]} (Bill Number: {tableBillId}) </span><span> (Last order: {this.formatDate(summarisedList[tableBillId]["maximumTimestamp"])})
                    {/* &nbsp;<Button label="Print All" onClick={() => null} /> */}
                    </span>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <thead>
                        <tr style={{ height: '10px'}}> </tr>
                            <tr>
                                <th style={{ border: '1px solid darkGrey', padding: '5px'  }}>Item Description</th>
                                <th style={{ textAlign: 'center', border: '1px solid darkGrey', padding: '5px'  }}>Price</th>
                                <th style={{ textAlign: 'center', border: '1px solid darkGrey', padding: '5px'  }}>Quantity</th>
                                <th style={{ textAlign: 'center', border: '1px solid darkGrey', padding: '5px'  }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        <React.Fragment>
                        {summary.items.map((item, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f3f2f2' : ' #e7e4e4'}}>
                            <td style={{ minWidth: '400px', border: '1px solid darkGrey', padding: '5px' }}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</td>
                            <td style={{ minWidth: '75px', border: '1px solid darkGrey', padding: '5px' }}>£{item.price.toFixed(2)}</td>
                            <td style={{ minWidth: '75px', border: '1px solid darkGrey', padding: '5px', textAlign: 'center' }}>{item.quantity}</td>
                            <td style={{ minWidth: '100px', border: '1px solid darkGrey', padding: '5px' }}>£{item.total.toFixed(2)}</td>
                            </tr>  
                        ))}
                        <tr><td></td><td></td><td></td><td style={{ border: '1px solid black', padding: '5px' }}>£{summarisedList[tableBillId]["billTotal"].toFixed(2)}</td></tr>
                        <tr style={{ height: '30px'}}> </tr>
                        </React.Fragment>
                        </tbody>
                    </table>
                    </div>
                ))
                }
            </div>);              
        };

    state = {
        "open": false,
        "statusID": -1,
        "seats": -1
    }

    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    componentDidMount() {
        this.props.getLast10Orders(this.props.user.token);
        this.props.loading(false);
    }


    addIcon = () => {
        const admin = (this.props.user.role === 'ADMIN');
        if (admin) {
            return <Fab onClick={() => { this.add() }}
                style={{
                    position: 'absolute',
                    bottom: 5,
                    right: 5
                }} color='primary'>
                <AddIcon />
            </Fab>
        }
    }

    render() {
        return (
            <div>
                <h1 style={{ margin: 10, color: '#3f51b5' }}>Order History</h1>
                {this.orderList()}
                {this.addIcon()}

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        orders: state.lastOrders,
        user: state.user,
        selectedTable: state.selectedTable
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getLast10Orders: getLast10Orders,
        loading: loading,
        setSelectedTable: setSelectedTable,
        changeNav: changeNav
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(OrdersHistory);
