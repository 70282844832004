import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadTables } from "../../actions/tableActions";
import { setSelectedTable } from "../../actions/selectedTableActions";
import { loading } from '../../actions/loadingActions'
import { changeNav } from '../../actions/navActions'
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createTabTable } from '../../actions/tableActions';
import { deleteTabTable } from '../../actions/tableActions';
import { removeTable } from "../../actions/tableActions";
import { changeTableId } from "../../actions/tableActions"


import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

class TablesList extends Component {

    edit = (item) => {
        this.props.loading(true);
        this.props.setSelectedTable(item);
        this.props.changeNav('editTable')
    }

    select = (item) => {
        this.props.loading(true);
        this.props.setSelectedTable(item);
        this.props.changeNav('productBill')
    }
    //WIP
    delete = async (item) => {
        this.props.loading(true);
        // this.props.setSelectedTable(item);
        // console.log("item", item, item.tableID)
        await this.props.deleteTabTable(this.props.user.token, item.tableID ); 
        this.props.removeTable(item.tableID); 
        this.props.loading(false);
    }
    // WIP
    iteratedTableBillItems = (tableId) => {
        const tables = this.props.tables;
        if (tables[0] && tables[0].bill) {
            return tables.filter(t=>t.tableID===tableId)[0].bill.length > 0;
        }
        return 1;
    }

    tableTotal=(tableBill)=>{
        let tableTotal = 0.00;
        tableBill.forEach((newBill) => {
            tableTotal += newBill.quantity * newBill.price;
        });
        return tableTotal.toFixed(2);
    }
    
    tableList = () => {
        return (<div className="p-grid" style={{ marginLeft: 5, marginRight: 5, display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
            {this.props.tables.filter((item) => item.statusID === (this.state.statusID) || this.state.statusID === -1)
            .filter((item) => this.state.seats >= 2? item.seats >= this.state.seats - 1 && item.seats <= this.state.seats : (item.seats <= this.state.seats || this.state.seats === -1))
            .filter(item=>this.isFiltered()?item.rollingBillId===null:true)
                .map((table,i) => {
                var string = "Unknown";
                var tableBack = "lightgrey";
                var buttonDisabled = "true";
                var buttonColour = "black";
                if (table.statusID === 0) {
                    string = "Unused";
                    tableBack = "linear-gradient(rgba(128, 128, 128, 0.95), rgba(128, 128, 128, 0.15))"; // grey
                    buttonDisabled = "true";
                    buttonColour = "grey";
                }

                if (table.statusID === 1) {
                    string = "Reserved";
                    tableBack = "linear-gradient(rgba(255, 160, 122, 0.95), rgba(255, 160, 122, 0.15))"; // orange
                    buttonDisabled = "false";
                    buttonColour = "orange";
                }
                if (table.statusID === 2) {
                    string = "Available";
                    tableBack = "linear-gradient(rgba(144, 238, 144, 0.95), rgba(144, 238, 144, 0.15))"; // green
                    buttonDisabled = "false";
                    buttonColour = "green";
                }
                if (table.statusID === 3) {
                    string = "Occupied";
                    tableBack = "linear-gradient(rgba(255, 192, 203, 0.95), rgba(255, 192, 203, 0.15))"; //pink
                    buttonDisabled = "false";
                    buttonColour = "#FF6B7A";
                }
                if (table.statusID === 4) {
                    string = "Cleaning";
                    tableBack = "linear-gradient(rgba(173, 216, 230, 0.95), rgba(173, 216, 230, 0.25))"; //blue
                    buttonDisabled = "false";
                    buttonColour = "blue";
                }
                var finalDate;
                if (table.statusID === 3) {
                    var convertedDate = new Date(table.occupiedTime);
                    finalDate = convertedDate.getHours() + ":" + (convertedDate.getMinutes()<10?'0':'') + convertedDate.getMinutes();
                }   
                else {
                }

                return <div key={table.tableID} className="p-col" style={{ border: table.rollingBillId>=1 ? '4px solid red' : '1px solid black', "background": tableBack, "margin": "5px", 
                    borderRadius: '5px', padding: '5px',fontFamily: 'Helvetica, Roboto'}} >
                    <p style={{ width: 200, margin: 5, backgroundColor: table.rollingBillId<=1 ? '#dadada' : '#F8F8F8', borderRadius: '5px', 
                    padding: '5px', textAlign: 'center', fontSize:'1.2em', fontWeight: '600', border: '1px solid #a7a7a7'}}>
                        {table.rollingBillId>1?'Tab:':'Table:'} {table.tempTable?`${table.tempTable} (${table.tableID})`:""}
                        </p><br />
                    <div style={{ margin:'5px', width: '95%'}}>
                    <span>
                            <span>Bill Total: <b>£{this.tableTotal(table.bill)}</b></span>
                    </span><br/>
                    <span>
                        {table.rollingBillId<=1 &&(
                            <span>Status: <b>{string}</b></span>
                        )}
                    </span><br/>
                    <span>
                        {table.rollingBillId<=1 &&(
                            <span>No of Seats: <b>{table.seats}</b></span>
                        )}
                    </span><br/>

                    <span>
                        {table.statusID===3 &&(
                            <span>Start Time: <b>{finalDate}</b></span>
                        )}
                    </span><br/>

                    {/* {(table.tableID)}ssss<br/> */}
                    {/* {this.iteratedTableBillItems(table.tableID)}ssss */}
                    </div>

                    
                    <Button onClick={() => this.select(table)} label={table.rollingBillId<=1 ?'Select Table':'Select Tab'} 
                    style={{ "backgroundColor": buttonColour, "margin": 5, cursor: "pointer", transition: "background-color 0.3s, color 0.3s"  }} />

                    {/* {this.setState({bill: this.props.loadBill(this.props.user.token, table.tableID)})} */}
                   
                    
                    {table.rollingBillId<=1 ?(
                        <Button onClick={() => this.edit(table)} label={"Edit Table"} 
                        style={{ "backgroundColor": buttonColour, "margin": 5, cursor: "pointer", transition: "background-color 0.3s, color 0.3s" }} />
                    ):(<Button onClick={() => this.delete(table)} label={"Delete Tab"} 

                    disabled={this.iteratedTableBillItems(table.tableID) > 0}  // Code to disable Delete button if there are any bill items against it. Allow deletion if no bill items.
                    
                    style={{ "backgroundColor": buttonColour, "margin": 5, cursor: "pointer", transition: "background-color 0.3s, color 0.3s" }} />)}

                    {(table.rollingBillId <= 1) ? (
                        <React.Fragment>
                            <Button onClick={() => this.handleClickOpenDeleteTable(table)} label={"Delete Table"}
                                disabled={this.iteratedTableBillItems(table.tableID) > 0}     // Code to disable Delete button if there are any bill items against it. Allow deletion if no bill items.
                                style={{ "backgroundColor": buttonColour, "margin": 5, cursor: "pointer", transition: "background-color 0.3s, color 0.3s" }}
                            />

                            <Dialog open={this.state.openDeleteTable} onClose={this.handleClickCloseDeleteTable}
                            BackdropProps={{
                                style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' }, 
                            }}
                            >
                                <DialogTitle>Confirm Delete</DialogTitle>
                                <DialogContent>
                                    This is a permanent table. Are you sure you want to delete it?
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClickCloseDeleteTable} color="primary" label={"Cancel"} style={{ "margin": "5px" }} />
                                    <Button onClick={()=>this.handleClickOkayDeleteTable(table)} color="primary" label={"Delete"} style={{ "margin": "5px" }} />
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    ) : ''}

                    {table.bill.length?(
                        <Button onClick={() => this.handleClickOpenConvertTabToTable(table)} label={"Transfer ALL bill items"} 
                        style={{ "backgroundColor": buttonColour, "margin": 5, cursor: "pointer", transition: "background-color 0.3s, color 0.3s" }} />
                    ):''}
                    
                </div>
            })}
        </div>
        )

    }

    // gb change - openCreateTabDialog & tabName 
    state = {
        "openCreateTabDialog": false,
        "openConvertTabToTable": false,
        "openDeleteTable": false,
        "tableToConvertFrom":"",
        "tableToConvertTo":"",
        "tableToDelete":"",
        "tabName": "",
        "open": false,
        "statusID": -1,
        "seats": -1,
        "tables": this.props.tables,
        "createTableChkboxVal":false,
    }

    handleClickConvertToTable = (table)=>{
        // console.log("handleClickConvertToTable called!!!!")
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.openConvertTabToTable} onClose={this.handleClickCloseConvertTabToTable }>
                <DialogTitle>Transfer All Bill Items</DialogTitle>
                <DialogContent>
                    <form>
                        <FormControl>
                            <p>Moving all items from:  <span style={{ color: 'red', fontWeight: 'bold', fontSize: '1.2em' }}>{this.state.tableToConvertFrom.tempTable} ({this.state.tableToConvertFrom.tableID})</span></p>
                            <label>to{this.table}</label>
                            
                            <Select
                                native
                                value={this.state.tableToConvertTo}
                                onChange={(e) => { this.setState({ tableToConvertTo: e.target.value }) }}
                                input={<Input id="table-id" 
                                // style={{"width":"100px"}}
                                />}
                            >
                                <option value="" disabled>Select Table/Tab</option>
                                
                                {this.props.tables.map(table =>
                                    this.state.tableToConvertFrom.tableID !== table.tableID ?
                                    // <option key={table.tableID} value={table.tableID}>Table {table.tableID}</option> : ''
                                    <option key={table.tableID} value={table.tableID}>{table.rollingBillId?`Tab ⇒`:`Table ⇒`} {`${table.tempTable} (${table.tableID})`}</option> : ''
                                )}
                            </Select>
                        </FormControl>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClickCloseConvertTabToTable} color="primary" label={"Cancel"} 
                    style={{ "margin": "5px" }} 
                    />

                    <Button onClick={()=>this.handleOkConvertTabToTable(this.state.selectedTableId)} color="primary" label={"Ok"} 
                    style={{ "margin": "5px" }} 
                    disabled = {!!!this.state.tableToConvertTo.length}
                    />
                    
                </DialogActions>
            </Dialog>
        );
    }

    handleChange = name => event => {
        this.setState({ [name]: Number(event.target.value) });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }


    handleClickOpenCreateTabDialog = () => {
        this.setState({ openCreateTabDialog: true });
    }

    handleCloseCreateTabDialog = () => {
        this.setState({ openCreateTabDialog: false });
        this.setState({tabName:""});
        this.setState({createTableChkboxVal:false});
    }

    handleClickOpenConvertTabToTable = (table) => {
        this.setState({ openConvertTabToTable: true });
        this.setState({tableToConvertFrom:table})
    }

    handleClickCloseConvertTabToTable = () => {
        this.setState({ openConvertTabToTable: false });
        this.setState({tableToConvertTo:""})
        this.setState({tableToConvertFrom:""})
    }

    handleClickOpenDeleteTable = (table) => {
        this.setState({ 
            openDeleteTable: true, 
            tableToDelete: table, 
        });
    }

    handleClickCloseDeleteTable = () => {
        this.setState({ openDeleteTable: false });
    }

    handleClickOkayDeleteTable = (table) => {
        this.delete(this.state.tableToDelete);
        this.setState({ openDeleteTable: false });
    }


    handleOkConvertTabToTable= async()=>{
        // console.log("handleOkConvertTabToTable clicked with tableID from/to of : ===>>", this.state.tableToConvertFrom.tableID, this.state.tableToConvertTo ); 
        this.setState({ openConvertTabToTable: false });
        // this.setState({tableToConvertTo:""})
        // this.setState({tableToConvertFrom:""})
        await this.props.changeTableId(this.props.user.token, this.state.tableToConvertFrom.tableID, this.state.tableToConvertTo );  // Also makes TableBillId the same for tab and bill items on tableToConvertTo (if it has any bill items)
        if (this.state.tableToConvertFrom.rollingBillId){      // Tabs only; not tables
            await this.props.deleteTabTable(this.props.user.token, this.state.tableToConvertFrom.tableID );        // Once tab is converted to a table, delete the tab.
        }
        await this.props.loadTables(this.props.user.token);
        
    }

    handleOkCreateTabButtonClick = async () => {
        const seats = 0;
        const statusId = 3;
        await this.props.createTabTable(statusId, seats, this.state.tabName, this.state.createTableChkboxVal, this.props.user.token);      // Create tab or normal table
        this.props.loading(true);
        // console.log("this.state=>",this.state); 
        await this.props.loadTables(this.props.user.token);
        this.handleCloseCreateTabDialog();
        this.props.loading(false);
    }

    handleRemoveFilters = () => {
        this.setState({
            statusID: -1,
            seats: -1,
        });
    }

    isFiltered() {
        return this.state.statusID !== -1 || this.state.seats !== -1;
    }
        
    tablesCreateTempTabTable = () => {
        return (
            <React.Fragment>
                <Button style={{ "position": "absolute", "right": "25%", "top": "-50px" }}  color="primary" variant="contained" 

                onClick={this.handleClickOpenCreateTabDialog} label={"Create Tab/Table"}/>
                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.openCreateTabDialog} onClose={this.handleCloseCreateTabDialog}>
                    <DialogTitle>Create a Tab</DialogTitle>
                    <div style={{ "padding":"0px 30px", "align-items": "center",  "margin-bottom": "10px"}}>
                        <input 
                            onChange={e=>this.setState({createTableChkboxVal: e.target.checked})}
                            style={{ transform: 'scale(1.5)' }} type="checkbox" id="chk-create-table"
                        />
                        <label style={{ "padding-left":"5px"}} htmlFor="chk-create-table"> Create a table</label>
                    </div>
                    <DialogContent>
                        <form>
                            <FormControl>
                                <InputLabel htmlFor="tab-name">Tab name</InputLabel>
                                <Input
                                    id="tab-name"
                                    onChange={(e) => { this.setState({ tabName: e.target.value }) }}
                                    autoFocus 
                                />
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCreateTabDialog} color="primary" label={"Cancel"} 
                        style={{ "margin": "5px" }} 
                        />

                        <Button onClick={this.handleOkCreateTabButtonClick} color="primary" label={"Ok"} 
                        style={{ "margin": "5px" }} 
                        disabled = {this.state.tabName.length<1 || this.state.tabName.length>12}
                        />
                        
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    tablesFilter = () => {
        return (
            <React.Fragment >
                <Button style={{ "position": "absolute", "left": "25%", "top": "-50px", border: this.isFiltered() ? '3px solid red' : 'none'}} color="primary" variant="contained" onClick={this.handleClickOpen} label={"Filter Tables"} 
                className="filtered"/>
                <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.open} onClose={this.handleClose}>
                    <DialogTitle>Enter Filters</DialogTitle>
                    <DialogContent>

                        <Button onClick={this.handleRemoveFilters} color="primary" label={"Remove ALL Filters"} style={{ "margin": "5px" }} disabled={!this.isFiltered()} /><p></p>
                        <form >
                            <FormControl >
                                <InputLabel htmlFor="age-native-simple">Table Status</InputLabel>
                                <Select
                                    native
                                    value={this.state.statusID}
                                    onChange={this.handleChange('statusID')}
                                    input={<Input id="age-native-simple" />}
                                >
                                    <MenuItem value="">
                                        <em>No filter</em>
                                    </MenuItem>
                                    <option value={-1}>No Filter</option>
                                    <option value={0}>Unused</option>
                                    <option value={1}>Reserved</option>
                                    <option value={2}>Available</option>
                                    <option value={3}>Occupied</option>
                                    <option value={4}>Cleaning</option>                                    
                                </Select>
                            </FormControl>
                            <FormControl >
                                <InputLabel htmlFor="age-native-simple">Table Seats</InputLabel>
                                <Select
                                    native
                                    value={this.state.seats}
                                    onChange={this.handleChange('seats')}
                                    input={<Input id="age-native-simple" />}
                                >
                                    <option value={-1}>No Filter</option>
                                    <option value={2}>&#8804; 2</option>
                                    <option value={4}>&#8804; 4</option>
                                    <option value={6}>&#8804; 6</option>
                                    <option value={8}>&#8804; 8</option>
                                    <option value={10}>&#8804; 10</option>
                                </Select>
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" label={"Cancel"} style={{ "margin": "5px" }} />

                        <Button onClick={this.handleClose} color="primary" label={"Ok"} style={{ "margin": "5px" }} />

                    </DialogActions>
                </Dialog>


            </React.Fragment>
        );
    }

    componentDidMount() {
        this.forceUpdate();
        this.props.loadTables(this.props.user.token);
        this.props.loading(false);
    }

    addIcon = () => {
        const admin = (this.props.user.role === 'ADMIN');
        if (admin) {
            return <Fab onClick={() => { this.add() }}
                style={{
                    position: 'absolute',
                    bottom: 5,
                    right: 5
                }} color='primary'>
                <AddIcon />
            </Fab>
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                {/* <h1 style={{ margin: 10, color: '#3f51b5' }}>Tables</h1>, */}
                <p></p>
                {this.tablesFilter()}
                {this.tablesCreateTempTabTable()}
                {this.handleClickConvertToTable()}
                {this.tableList()}
                {this.addIcon()}
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        tables: state.tables,
        user: state.user,
        selectedTable: state.selectedTable,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        loadTables: loadTables,
        loading: loading,
        setSelectedTable: setSelectedTable,
        changeNav: changeNav,
        createTabTable: createTabTable,
        deleteTabTable: deleteTabTable,
        removeTable: removeTable,
        changeTableId: changeTableId,
    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(TablesList);
