export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOADING_SHOW = 'LOADING_SHOW';
export const CHANGE_NAVIGATION = 'CHANGE_NAVIGATION';
export const MESSAGE_SHOW = 'MESSAGE_SHOW';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_TABLES = 'LOAD_TABLES';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const SET_SELECTED_TABLE = 'SET_SELECTED_TABLE';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_BILL = 'LOAD_BILL';
export const LOAD_LAST_ORDERS = 'LOAD_LAST_ORDERS';
export const DELETE_TABLE = 'DELETE_TABLE';